import { Button } from '@teamsnap/teamsnap-ui';
import { Header } from 'components/shared';
import { ActionContainer } from 'components/shared/ActionContainer';
import { ACCOUNTS_URL } from 'core/constants';
import React from 'react';
import { useOrderHelpers } from 'state/hooks/useOrderHelpers';
import { useInstallmentStateSelector } from 'state/installments/installmentsSlice';
import { useAthletiGuardOfferingSelector, useFormMessageSelector, useFormResultsSelector, useOrderStateSelector } from 'state/order/orderSlice';
import { useHouseholdPeopleSelector, useUserSelector } from 'state/user/userSlice';
import './Confirmation.scss';
import {
  AddonSummary,
  BalanceSummary,
  ConfirmationMessage,
  InstallmentLineItem,
  InstallmentsRollup,
  OrderDetails,
  OrderSummary,
  OrderTotalSummary,
  SuccessHeading
} from './PageElements';

// As we touch other Page Elements, move them out to their own components
import { AthletiGuardOffering } from './PageElements/AthletiGuardOffering';

const INSTALLMENT_ROLLUP_THRESHOLD = 6;

export const OrderConfirmation = () => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const [includedAddons, setIncludedAddons] = React.useState<string[]>([]);
  const orderData = useOrderStateSelector();
  const installments = useInstallmentStateSelector();
  const householdPeople = useHouseholdPeopleSelector();
  const formResults = useFormResultsSelector();
  const confirmationMessage = useFormMessageSelector();
  const athletiGuardOffering = useAthletiGuardOfferingSelector();
  const user = useUserSelector();

  React.useEffect(() => {
    if (orderData) {
      setIncludedAddons(Array.from(new Set(orderData.orderLineItems.filter(lineItem => lineItem.scopeType === 'partner_offering').map(lineItem => lineItem.name))));
    }
  }, [orderData])

  const { latestPaymentMethodText, orderDateText, totalProcessingFeesText, totalDiscountsText, subtotalText, balanceText, totalText } =
    useOrderHelpers();
  const hasRollup = installments?.length && installments.length > INSTALLMENT_ROLLUP_THRESHOLD;

  const handleBackButton = () => {
    window.location.href = `${ACCOUNTS_URL}/orders`;
  };

  return (
    <>
      <ActionContainer
        removeContentFormatting={true}
        submitting={false}
        contentStyles={{ backgroundColor: 'white' }}
        header={
          <Header
            title="Order - confirmation"
            profileName={`${user?.firstName} ${user?.lastName}`}
            navigation={
              <Button
                iconPosition="left"
                mods="back-button sui-m-0 sui-w-auto sui-text-gray-10"
                icon="arrow-left"
                type="link"
                size="large"
                onClick={handleBackButton}
              />
            }
          />
        }
      >
        <div className="sui-p-2">
          <SuccessHeading text="Your order confirmation" />
          <div className="sui-pb-1 sui-body sui-border-b sui-border-t-0 sui-border-x-0 sui-border-b-gray-5 sui-border-dashed">
            <OrderSummary
              orderNumber={orderData?.orderNumber}
              date={orderDateText}
              paymentMethodText={latestPaymentMethodText}
            />
            <OrderDetails orderLineItems={orderData?.orderLineItems} {...{ householdPeople, formResults }} />
            <OrderTotalSummary {...{ totalProcessingFeesText, totalDiscountsText, subtotalText, totalText }} />
            <BalanceSummary {...{ balanceText }} />
            {isExpanded || !hasRollup ? (
              installments?.map((installment) => <InstallmentLineItem installment={installment} key={installment.id} />)
            ) : (
              <>
                {installments?.slice(0, 4).map((installment) => (
                  <InstallmentLineItem installment={installment} key={installment.id} />
                ))}
                <InstallmentsRollup installments={installments.slice(4)} onClickHandler={() => setIsExpanded(true)} />
              </>
            )}
          </div>
          {athletiGuardOffering && <AthletiGuardOffering { ...athletiGuardOffering }/>}
          {confirmationMessage?.content && <ConfirmationMessage html={confirmationMessage.content} />}
          {includedAddons.length > 0 && <AddonSummary includedAddons={includedAddons}/>}
        </div>
      </ActionContainer>
    </>
  );
};
