import { Header } from 'components/shared';
import { ActionContainer } from 'components/shared/ActionContainer';
import React from 'react';
import { useInstallmentHelpers } from 'state/hooks/useInstallmentHelpers';
import { formatMethod, useOrderHelpers } from 'state/hooks/useOrderHelpers';
import { usePaymentResultHelpers } from 'state/hooks/usePaymentResultHelpers';
import { useAthletiGuardOfferingSelector, useFormMessageSelector, useFormResultsSelector, useOrderStateSelector } from 'state/order/orderSlice';
import { useHouseholdPeopleSelector, useUserSelector } from 'state/user/userSlice';
import './Confirmation.scss';
import {
  AddonSummary,
  BalanceSummary,
  ConfirmationMessage,
  InstallmentSummary,
  OrderDetails,
  OrderSummary,
  OrderTotalSummary,
  PaymentSummary,
  SuccessHeading,
} from './PageElements';

// As we touch other Page Elements, move them out to their own components
import { AthletiGuardOffering } from './PageElements/AthletiGuardOffering';

export const PaymentConfirmation = () => {
  const [includedAddons, setIncludedAddons] = React.useState<string[]>([]);
  const orderData = useOrderStateSelector();
  const householdPeople = useHouseholdPeopleSelector();
  const formResults = useFormResultsSelector();
  const { fee, subtotal, total, showFee, paymentMethod } = usePaymentResultHelpers();
  const confirmationMessage = useFormMessageSelector();
  const athletiGuardOffering = useAthletiGuardOfferingSelector();


  const user = useUserSelector();
  const { isInstallmentPayment, todaysPayment, installmentsLeft } = useInstallmentHelpers();
  const { orderDateText, totalProcessingFeesText, totalDiscountsText, subtotalText, balanceText, totalText } = useOrderHelpers();

  React.useEffect(() => {
    if (orderData) {
      setIncludedAddons(Array.from(new Set(orderData.orderLineItems.filter(lineItem => lineItem.scopeType === 'partner_offering').map(lineItem => lineItem.name))));
    }
  }, [orderData])

  return (
    <>
      <ActionContainer
        removeContentFormatting={true}
        submitting={false}
        contentStyles={{ backgroundColor: 'white' }}
        header={<Header title="Order - confirmation" profileName={`${user?.firstName} ${user?.lastName}`} />}
      >
        <div className="sui-p-2">
          <SuccessHeading
            text="Your payment is complete."
            body={
              <>
                A confirmation email with receipt has <br />
                been sent to you.
              </>
            }
          />
          <div className="sui-pb-1 sui-body sui-border-b sui-border-t-0 sui-border-x-0 sui-border-b-gray-5 sui-border-dashed">
            <OrderSummary
              orderNumber={orderData?.orderNumber}
              date={orderDateText}
              paymentMethodText={formatMethod(paymentMethod)}
            />
            <OrderDetails orderLineItems={orderData?.orderLineItems} {...{ householdPeople, formResults }} />
            <OrderTotalSummary {...{ totalProcessingFeesText, totalDiscountsText, subtotalText, totalText }} />
            <BalanceSummary {...{ balanceText }} />
            {isInstallmentPayment && <InstallmentSummary {...{ todaysPayment, installmentsLeft }} />}
            {subtotal && fee && <PaymentSummary {...{ subtotal, showFee, fee, total, isInstallmentPayment }} />}
          </div>
          {athletiGuardOffering && <AthletiGuardOffering { ...athletiGuardOffering }/>}
          {confirmationMessage?.content && <ConfirmationMessage html={confirmationMessage.content} />}
          {includedAddons.length > 0 && <AddonSummary includedAddons={includedAddons}/>}
        </div>
      </ActionContainer>
    </>
  );
};
