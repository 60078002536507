import { ampli } from '../../core/ampli';

export enum AMPLITUDE_SCREEN_NAMES {
  'installments' = 'Registrant Choose Installment Plan',
  'payment' = 'Registrant Payment Method',
  'confirmation' = 'Registrant Confirmation Message',
  'addons' = 'Registrant Add-ons',
}

export enum AMPLITUDE_INSURANCE_PROVIDER {
  ATHLETIGUARD = 'Athletiguard',
}

export enum AMPLITUDE_REG_VERSION {
  REG_1_OR_15 = 'Classic',
  REG_2 = 'Reg 2',
}

interface AmplitudeTrackActions {
  screenViewed: (path: string | undefined, orderId: string | undefined) => void;
  registrationInsuranceViewed: () => void;
  registrationInsuranceClicked: () => void;
}

// Define the amplitude functions available for use through the hook
export const useAmplitude = () => {
  // Exposing method to identify user in Amplitude
  const amplitudeIdentify = (uuid: string, email: string) => {
    ampli.identify(uuid, {
      email: email,
    });
  };

  // Exposing methods to track events in Amplitude
  const amplitudeTrack: AmplitudeTrackActions = {
    // Tracks which page pathname the user is viewing
    screenViewed: (path: string | undefined, orderId: string | undefined) => {
      // we just want to track these 3 pages from the registrant flow for now
      if (path && ['payment', 'installments', 'confirmation', 'addons'].indexOf(path) >= 0) {
        const matchedAmplitudeScreenName = AMPLITUDE_SCREEN_NAMES[path as keyof typeof AMPLITUDE_SCREEN_NAMES];
        if (matchedAmplitudeScreenName && orderId) {
          ampli.screenViewed({
            screen_name: matchedAmplitudeScreenName,
            url: `/order/${orderId}/${path}`,
          });
        }
      }
    },

    registrationInsuranceViewed: () => {
      ampli.registrationInsuranceViewed({
        reg_version: AMPLITUDE_REG_VERSION.REG_2,
        provider: AMPLITUDE_INSURANCE_PROVIDER.ATHLETIGUARD,
      });
    },

    registrationInsuranceClicked: () => {
      ampli.registrationInsuranceClicked({
        reg_version: AMPLITUDE_REG_VERSION.REG_2,
         provider: AMPLITUDE_INSURANCE_PROVIDER.ATHLETIGUARD
      });
    },
  };

  return { amplitudeIdentify, amplitudeTrack };
};
