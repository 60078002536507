import React from 'react';

import { LabelButton } from '@teamsnap/snap-ui';
import { useAmplitude } from 'state/hooks/useAmplitude';

export const AthletiGuardOffering = ({ productName, description, linkDescription, linkBaseUrl }: any) => {
  const { amplitudeTrack } = useAmplitude();

  React.useEffect(() => {
    amplitudeTrack.registrationInsuranceViewed();
  }, []);

  const handleLinkClick = () => {
    amplitudeTrack.registrationInsuranceClicked();
    window.open(linkBaseUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div className="sui-pb-2 sui-border-b sui-border-b sui-border-x-0 sui-border-b-gray-5  sui-border-solid">
        <div
          className="sui-mt-2 sui-my-1 sui-pt-2 sui-pb-0.5 sui-bg-info-background sui-rounded sui-p-2"
          data-testid="athletiguard"
        >
          <h4 className="sui-mt-2 sui-body" data-testid="athletiguard-heading">
            Protect your season costs
          </h4>
          <br />
          <div
            className="sui-body sui-text-gray-8 sui-font-normal"
            data-testid="athletiguard-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <br />
          <LabelButton
            data-testid="athletiguard-link"
            icon="chevron_right"
            iconPosition="right"
            labelText={linkDescription}
            sentiment="default"
            size="default"
            className="sui-mr-1 sui-mb-2"
            variantType="primary"
            onClick={() => handleLinkClick()}
          ></LabelButton>
        </div>
      </div>
    </>
  );
};
